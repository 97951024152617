<template>
  <layout :title="$route.name" :toolbar_off="true">
    <v-flex xs12>
      <v-flex xs12 class="pa-3 pb-0">
        <div class="expande-horizontal centraliza">
          <div class="expande-horizontal column title-item">
            <v-avatar
              @click="$router.go(-1)"
              class="card-balance-style"
              size="36"
              style="cursor: pointer;"
              color="#555"
              icon
            >
              <v-icon color="#f2f2f2" size="26">mdi-arrow-left</v-icon>
            </v-avatar>
          </div>
          <div
            style="display: flex; align-items: center; justify-content: center; height: 40px;"
          >
            <v-icon color="#f2f2f2" size="38" style="padding-bottom: 3px;"
              >mdi-link</v-icon
            >
            <span
              style="font-size: 19pt; text-wrap: nowrap;"
              class="fonte-bold white--text"
            >
              Corretora
            </span>
          </div>
        </div>
      </v-flex>
      <v-flex xs12>
        <div class="expande-horizontal centraliza">
          <v-window touchless v-model="step">
            <v-window-item value="0">
              <v-flex xs12 class="px-3">
                <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
                  <v-flex class="px-1" xs12>
                    <span
                      class="fonte-bold white--text"
                      style="font-size: 24pt;"
                    >
                      1. Cadastre-se no link abaixo
                    </span>
                  </v-flex>
                  <v-flex xs12>
                    <v-list class="px-3" color="transparent">
                      <v-list-item
                        @click="goToRegister"
                        class="card-balance-style"
                        style="border-radius: 16px; margin-bottom: 6px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="white--text fonte-bold">
                            FXTM
                          </v-list-item-title>
                          <v-list-item-subtitle class="grey--text fonte">
                            ForexTime, clique aqui para acessar!
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon color="white">mdi-open-in-new</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                  <v-flex xs12>
                    <div class="expande-horizontal column centraliza">
                      <v-btn
                        @click="step = '2'"
                        :loading="loading"
                        color="orange"
                        dark
                        rounded
                        class="fonte-bold"
                      >
                        <span class="fonte-bold"
                          >Já me cadastrei, avançar!</span
                        >
                      </v-btn>
                    </div>
                  </v-flex>
                </div>
              </v-flex>
            </v-window-item>

            <!-- <v-window-item value="1">
              <v-flex xs12 class="px-3">
                <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
                  <v-flex class="px-1" xs12>
                    <span
                      class="fonte-bold white--text"
                      style="font-size: 24pt;"
                    >
                      2. Conectar na RealOperate
                    </span>
                  </v-flex>
                  <v-flex xs12>
                    <v-list class="px-3" color="transparent">
                      <v-list-item
                        @click="goToConnect"
                        class="card-balance-style"
                        style="border-radius: 16px; margin-bottom: 6px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="white--text fonte-bold">
                            RealOperate
                          </v-list-item-title>
                          <v-list-item-subtitle class="grey--text fonte">
                            Clique aqui para conectar!
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon color="white">mdi-open-in-new</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                  <v-flex xs12>
                    <div class="expande-horizontal column centraliza">
                      <v-btn
                        @click="step = '2'"
                        :loading="loading"
                        color="orange"
                        dark
                        rounded
                        class="fonte-bold"
                      >
                        <span class="fonte-bold">Já conectei, avançar!</span>
                      </v-btn>
                    </div>
                  </v-flex>
                </div>
              </v-flex>
            </v-window-item> -->

            <v-window-item value="2">
              <v-flex xs12 class="px-3">
                <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
                  <v-flex class="px-1" xs12>
                    <span
                      class="fonte-bold white--text"
                      style="font-size: 24pt;"
                    >
                      ID FXTM - ForexTime
                    </span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      filled
                      color="white"
                      rounded
                      dark
                      placeholder="ID da Corretora"
                      v-mask="[
                        '#',
                        '##',
                        '###',
                        '####',
                        '#####',
                        '######',
                        '#########'
                      ]"
                      large
                      v-model="getLoggedUser.a_id"
                      autofocus
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <div class="expande-horizontal column centraliza">
                      <v-btn
                        @click="submit"
                        :loading="loading"
                        color="orange"
                        dark
                        rounded
                        class="fonte-bold"
                      >
                        <span class="fonte-bold">Cadastrar ID</span>
                      </v-btn>
                    </div>
                  </v-flex>
                </div>
              </v-flex>
            </v-window-item>

            <v-window-item value="3">
              <v-flex xs12 class="px-3">
                <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
                  <v-flex class="px-1" xs12>
                    <span
                      class="fonte-bold mb-6 expande-horizontal centraliza"
                      style="font-size: 24pt;"
                    >
                      <v-avatar size="120" color="green">
                        <v-icon color="white" size="100">mdi-check</v-icon>
                      </v-avatar>
                    </span>
                  </v-flex>

                  <v-flex class="px-1" xs12>
                    <div class="expande-horizontal centraliza column">
                      <span
                        class="fonte-bold white--text"
                        style="font-size: 24pt;"
                      >
                        ID Cadastrado
                      </span>
                      <span
                        class="fonte grey--text mb-2"
                        style="font-size: 12pt;"
                      >
                        Próximo passo: entre na banca da RealOperate no link
                        abaixo!
                      </span>
                      <v-flex xs12>
                        <v-list class="px-3" color="transparent">
                          <v-list-item
                            @click="goToConnect"
                            class="card-balance-style"
                            style="border-radius: 16px; margin-bottom: 6px;"
                          >
                            <v-list-item-content>
                              <v-list-item-title class="white--text fonte-bold">
                                RealOperate
                              </v-list-item-title>
                              <v-list-item-subtitle class="grey--text fonte">
                                Clique aqui para conectar!
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-icon color="white">mdi-open-in-new</v-icon>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-flex>
                      <span
                        class="fonte-bold grey--text mb-2"
                        style="font-size: 12pt;"
                      >
                        ID: {{ getLoggedUser.a_id }}
                      </span>
                    </div>
                  </v-flex>
                  <!-- <v-flex xs12 class="mt-3">
                    <div class="expande-horizontal centraliza">
                      <v-btn
                        @click="$router.push('/dashboard')"
                        color="black"
                        dark
                        large
                        rounded
                        class="fonte-bold"
                        >Ir para o início</v-btn
                      >
                    </div>
                  </v-flex> -->
                  <v-flex xs12 class="mt-3">
                    <!-- <div class="expande-horizontal centraliza">
                      <v-btn
                        @click="step = '1'"
                        color="black"
                        dark
                        large
                        text
                        rounded
                        class="fonte-bold"
                        >Editar ID</v-btn
                      >
                    </div> -->
                  </v-flex>
                </div>
              </v-flex>
            </v-window-item>
            <v-window-item value="4">
              <v-flex xs12 class="px-3">
                <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
                  <v-flex class="px-1" xs12>
                    <span
                      class="fonte-bold mb-6 expande-horizontal centraliza"
                      style="font-size: 24pt;"
                    >
                      <v-avatar size="120" color="orange">
                        <v-icon color="white" size="60"
                          >mdi-alert-outline</v-icon
                        >
                      </v-avatar>
                    </span>
                  </v-flex>
                  <v-flex class="px-1" xs12>
                    <div class="expande-horizontal centraliza column">
                      <span
                        class="fonte-bold white--text"
                        style="font-size: 24pt;"
                      >
                        Opa!
                      </span>
                      <span
                        class="fonte grey--text mb-2"
                        style="font-size: 12pt;"
                      >
                        {{ error_message }}
                      </span>
                    </div>
                  </v-flex>
                  <v-flex xs12 class="mt-3">
                    <div class="expande-horizontal centraliza">
                      <v-btn
                        @click="step = '0'"
                        color="black"
                        dark
                        large
                        rounded
                        class="fonte-bold"
                        >Tentar novamente</v-btn
                      >
                    </div>
                  </v-flex>
                  <v-flex xs12 class="mt-3">
                    <div class="expande-horizontal centraliza">
                      <v-btn
                        @click="$router.push('/dashboard')"
                        color="white"
                        dark
                        text
                        class="fonte-bold"
                        >Ir para o início</v-btn
                      >
                    </div>
                  </v-flex>
                </div>
              </v-flex>
            </v-window-item>
          </v-window>
        </div>
      </v-flex>
    </v-flex>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      step: "0",
      loading: false,
      error_message: ""
    };
  },
  methods: {
    ...mapActions(["registerBrokerCode"]),
    submit() {
      this.loading = true;
      this.registerBrokerCode(this.getLoggedUser.a_id)
        .then(() => {
          this.loading = false;
          this.step = "3";
        })
        .catch(e => {
          this.loading = false;
          this.error_message = e.response.data.message;
          this.step = "4";
        });
    },
    goToRegister() {
      window.open("https://www.forextime.com/en/?partner_id=4943105", "_blank");
    },
    goToConnect() {
      window.open("https://www.forextime.com/strategy/REAL-OPERATE", "_blank");
    }
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  },
  mounted() {
    if (this.getLoggedUser.a_id) {
      this.step = "3";
    }
  }
};
</script>
